import $, { now } from 'jquery';
import 'what-input';
import '../../../node_modules/slick-carousel/slick/slick.min';
import Chart from 'chart.js';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

// Cookies
$(document).foundation();
if(localStorage.getItem('cookieSeen') != 'shown'){
    $(".cookie-banner").delay(2000).fadeIn();
    localStorage.setItem('cookieSeen','shown')
}

$('.cookie-close').click(function(e) {
  $('.cookie-banner').fadeOut(); 
});
$(document).ready(() => {
    var countDownDate = new Date('1 Sept 2021');
    var nowTest = new Date().getTime();
    var testDate = countDownDate - nowTest;
    if(testDate > 0){
    var x = setInterval(() => {
            var now = new Date().getTime();
            var distance = countDownDate - now;

            $('.days').text(Math.floor(distance / (1000 * 60 * 60 * 24)));
            $('.hours').text(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
            $('.min').text(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
            $('.sec').text(Math.floor((distance % (1000 * 60)) / 1000));
        },1000);
    }
});


//Slick Carousel


$(document).ready(()=>{


    //Partners-carousel
    $('.partners-carousel').slick({
        autoplay: true,
        autplaySpeed: 5000,
        dots: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1
                }
            }
        ]
    });


    //technologies-carousel
    $('.technologies-carousel').slick({
        centerMode: true,
        autoplay: false,
        autplaySpeed: 5000,
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1
                }
            }
        ]
    });
});

//Video Effects
var position = $(window).scrollTop(); 
$(window).scroll(()=>{
    var scroll= $(window).scrollTop();
    if(scroll > position){
        $('video.hidden').fadeIn(2000);
    }
    else{
        $('video.hidden').fadeOut(2000);
        $('.banner-change').fadeIn("slow");
    }
    position = scroll;
});

$(document).ready(()=>{
    $('.banner-change').click(function(){
        $('.banner-change').fadeOut("slow");
        $('video.hidden').fadeIn(2000);
    });
});


//Display Charts
$(document).ready(()=>{
    var numCharts = $('.chart').length; //count charts
    var chartCount = 1; //counter


    while(numCharts>=chartCount){
        var spidyId = $('.chart:nth-of-type('+chartCount+')').data('id');
        var spidyChart = $('#spidyChart'+spidyId);
        var spidyLables = spidyChart.data('lable').split(',');
        var spidyValue = spidyChart.data('value').split(',');
        
        var myChart = new Chart(spidyChart, {
            type: 'radar',
            data: {
                labels: spidyLables,
                datasets: [{
                    data: spidyValue
                }]
            },
            options: {
                scale: {
                    angleLines: {
                        display: false
                    },
                    ticks: {
                        suggestedMin: 0,
                        suggestedMax: 5
                    }
                }
            }    
        });
        chartCount++;
    }
    
    
});
